import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "canvas__panel__grouped__styles" }
const _hoisted_2 = { class: "canvas__panel__tabs" }
const _hoisted_3 = {
  key: 2,
  class: "canvas__panel__styles__row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorizontalAlignStyle = _resolveComponent("HorizontalAlignStyle")!
  const _component_VerticalAlignStyle = _resolveComponent("VerticalAlignStyle")!
  const _component_PanelTab = _resolveComponent("PanelTab")!
  const _component_PaddingStyle = _resolveComponent("PaddingStyle")!
  const _component_FontStyle = _resolveComponent("FontStyle")!
  const _component_TextColorStyle = _resolveComponent("TextColorStyle")!
  const _component_FontSizeStyle = _resolveComponent("FontSizeStyle")!
  const _component_FontWeightStyle = _resolveComponent("FontWeightStyle")!
  const _component_LineHeightStyle = _resolveComponent("LineHeightStyle")!
  const _component_LetterSpacingStyle = _resolveComponent("LetterSpacingStyle")!
  const _component_TextAlignStyle = _resolveComponent("TextAlignStyle")!
  const _component_ContentStyle = _resolveComponent("ContentStyle")!
  const _component_BackgroundColorStyle = _resolveComponent("BackgroundColorStyle")!
  const _component_BorderRadiusStyle = _resolveComponent("BorderRadiusStyle")!
  const _component_ShadowStyle = _resolveComponent("ShadowStyle")!
  const _component_HrefAttribute = _resolveComponent("HrefAttribute")!
  const _component_ImageAttribute = _resolveComponent("ImageAttribute")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showTab(_ctx.tabsStyles.layout))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 0,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.layout,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.layout.index]
          }, {
            default: _withCtx(() => [
              (_ctx.hasAttributes('align'))
                ? (_openBlock(), _createBlock(_component_HorizontalAlignStyle, {
                    key: 0,
                    "is-parent": _ctx.isParentAttribute('align')
                  }, null, 8, ["is-parent"]))
                : _createCommentVNode("", true),
              (_ctx.hasAttributes('valign'))
                ? (_openBlock(), _createBlock(_component_VerticalAlignStyle, {
                    key: 1,
                    "is-parent": _ctx.isParentAttribute('valign')
                  }, null, 8, ["is-parent"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true),
      (_ctx.showTab(_ctx.tabsStyles.spacing))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 1,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.spacing,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.spacing.index]
          }, {
            default: _withCtx(() => [
              (_ctx.showStyle('padding'))
                ? (_openBlock(), _createBlock(_component_PaddingStyle, {
                    key: 0,
                    "is-parent": _ctx.isParentStyle('padding')
                  }, null, 8, ["is-parent"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true),
      (_ctx.showTab(_ctx.tabsStyles.typography))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 2,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.typography,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.typography.index]
          }, {
            default: _withCtx(() => [
              (_ctx.showStyle('font-family'))
                ? (_openBlock(), _createBlock(_component_FontStyle, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.showStyle('color'))
                ? (_openBlock(), _createBlock(_component_TextColorStyle, { key: 1 }))
                : _createCommentVNode("", true),
              (_ctx.showTypographyRow)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.showStyle('font-size'))
                      ? (_openBlock(), _createBlock(_component_FontSizeStyle, { key: 0 }))
                      : _createCommentVNode("", true),
                    (_ctx.showStyle('font-weight'))
                      ? (_openBlock(), _createBlock(_component_FontWeightStyle, { key: 1 }))
                      : _createCommentVNode("", true),
                    (_ctx.showStyle('line-height'))
                      ? (_openBlock(), _createBlock(_component_LineHeightStyle, { key: 2 }))
                      : _createCommentVNode("", true),
                    (_ctx.showStyle('letter-spacing'))
                      ? (_openBlock(), _createBlock(_component_LetterSpacingStyle, { key: 3 }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showStyle('text-align'))
                ? (_openBlock(), _createBlock(_component_TextAlignStyle, {
                    key: 3,
                    "is-parent": _ctx.isParentStyle('padding')
                  }, null, 8, ["is-parent"]))
                : _createCommentVNode("", true),
              (_ctx.hasContent())
                ? (_openBlock(), _createBlock(_component_ContentStyle, { key: 4 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true),
      (_ctx.showTab(_ctx.tabsStyles.background))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 3,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.background,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.background.index]
          }, {
            default: _withCtx(() => [
              (_ctx.showStyle('background-color'))
                ? (_openBlock(), _createBlock(_component_BackgroundColorStyle, {
                    key: 0,
                    "is-parent": _ctx.isParentStyle('background-color')
                  }, null, 8, ["is-parent"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true),
      (_ctx.showTab(_ctx.tabsStyles.borders))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 4,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.borders,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.borders.index]
          }, {
            default: _withCtx(() => [
              (_ctx.showStyle('border-radius'))
                ? (_openBlock(), _createBlock(_component_BorderRadiusStyle, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true),
      (_ctx.showTab(_ctx.tabsStyles.effects))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 5,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.effects,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.effects.index]
          }, {
            default: _withCtx(() => [
              (_ctx.showStyle('box-shadow'))
                ? (_openBlock(), _createBlock(_component_ShadowStyle, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true),
      (_ctx.showTab(_ctx.tabsStyles.link))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 6,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.link,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.link.index]
          }, {
            default: _withCtx(() => [
              (_ctx.hasAttributes('href'))
                ? (_openBlock(), _createBlock(_component_HrefAttribute, {
                    key: 0,
                    "is-parent": _ctx.isParentAttribute('href')
                  }, null, 8, ["is-parent"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true),
      (_ctx.showTab(_ctx.tabsStyles.media))
        ? (_openBlock(), _createBlock(_component_PanelTab, {
            key: 7,
            onUpdate: _ctx.setActiveTab,
            onClose_tabs: _ctx.closeAllTabs,
            properties: _ctx.tabsStyles.media,
            "show-body": _ctx.tabStates[_ctx.tabsStyles.media.index]
          }, {
            default: _withCtx(() => [
              (_ctx.hasAttributes('src'))
                ? (_openBlock(), _createBlock(_component_ImageAttribute, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onUpdate", "onClose_tabs", "properties", "show-body"]))
        : _createCommentVNode("", true)
    ])
  ]))
}