import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "projects__skeletons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
      return _createElementVNode("div", {
        key: i,
        class: "projects__skeletons__item"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "projects__skeletons__item__img" }, null, -1),
        _createElementVNode("div", { class: "projects__skeletons__item__title" }, null, -1),
        _createElementVNode("div", { class: "projects__skeletons__item__desc" }, null, -1)
      ]))
    }), 64))
  ]))
}