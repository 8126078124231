import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    type: "button",
    class: "button"
  }, [
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 1,
          class: "loader",
          icon: "loader"
        }))
  ], 8, _hoisted_1))
}