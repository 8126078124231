import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = { value: "" }
const _hoisted_3 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", _mergeProps({
    class: "base__select",
    value: _ctx.modelValue
  }, {
      ..._ctx.$attrs,
      onChange: ($event) => {
        _ctx.$emit('update:modelValue', $event.target.value);
      },
    }), [
    _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, key) => {
      return (_openBlock(), _createElementBlock("option", {
        key: key,
        value: option.value,
        selected: option.value === _ctx.modelValue
      }, _toDisplayString(option.title), 9, _hoisted_3))
    }), 128))
  ], 16, _hoisted_1))
}