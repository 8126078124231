import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__container" }
const _hoisted_3 = { class: "header__container__left" }
const _hoisted_4 = { class: "header__logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeaderProjectInput = _resolveComponent("HeaderProjectInput")!
  const _component_HeaderContainerMiddle = _resolveComponent("HeaderContainerMiddle")!
  const _component_HeaderContainerRight = _resolveComponent("HeaderContainerRight")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BaseIcon, { icon: "logo/white" })
        ]),
        (_ctx.isCanvas && !_ctx.isPreview)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: "/projects",
              class: "header__home"
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", null, [
                  _createVNode(_component_BaseIcon, { icon: "arrow/left" })
                ]),
                _createElementVNode("button", null, [
                  _createVNode(_component_BaseIcon, { icon: "header/home" })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isPreview && _ctx.project)
          ? (_openBlock(), _createBlock(_component_HeaderProjectInput, {
              key: 1,
              "is-canvas": _ctx.isCanvas,
              "is-disabled": true
            }, null, 8, ["is-canvas"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.project)
        ? (_openBlock(), _createBlock(_component_HeaderContainerMiddle, {
            key: 0,
            "is-auth": _ctx.isAuth,
            "is-canvas": _ctx.isCanvas,
            "is-preview": _ctx.isPreview,
            "current-preview": _ctx.currentPreview
          }, null, 8, ["is-auth", "is-canvas", "is-preview", "current-preview"]))
        : _createCommentVNode("", true),
      _createVNode(_component_HeaderContainerRight, {
        "is-auth": _ctx.isAuth,
        "is-canvas": _ctx.isCanvas,
        "is-preview": _ctx.isPreview
      }, null, 8, ["is-auth", "is-canvas", "is-preview"])
    ])
  ]))
}