import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["workspace__component__drop__indicator", _ctx.style.layout])
  }, [
    _createElementVNode("span", null, [
      _createVNode(_component_BaseIcon, { icon: "canvas/workspace/drop-indicator" })
    ])
  ], 2))
}