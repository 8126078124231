import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "projects__header" }
const _hoisted_2 = { class: "projects__header__search__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      id: "modals-trigger",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.createProject && _ctx.createProject(...args))),
      class: "projects__header__create"
    }, [
      _createVNode(_component_BaseIcon, { icon: "add" }),
      _cache[5] || (_cache[5] = _createElementVNode("span", null, "Create new project", -1))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["projects__header__search", {
        focus: _ctx.isInputFocused,
      }])
    }, [
      _withDirectives(_createElementVNode("input", {
        class: "projects__header__search__input",
        placeholder: "Search projects",
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchName) = $event)),
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
        onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
      }, null, 544), [
        [_vModelText, _ctx.searchName]
      ]),
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.searchName && !_ctx.isInputFocused)
          ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              icon: "search"
            }))
          : (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 1,
              onClick: _ctx.reset,
              class: "active",
              icon: "close-circle"
            }, null, 8, ["onClick"]))
      ])
    ], 2)
  ]))
}