import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app__container" }
const _hoisted_2 = { class: "app__header" }
const _hoisted_3 = { class: "app__content__container" }
const _hoisted_4 = { class: "app__body" }
const _hoisted_5 = { class: "app__content__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectDuplicate = _resolveComponent("ProjectDuplicate")!
  const _component_ProjectUpdate = _resolveComponent("ProjectUpdate")!
  const _component_ProjectDelete = _resolveComponent("ProjectDelete")!
  const _component_FolderDuplicate = _resolveComponent("FolderDuplicate")!
  const _component_FolderCreate = _resolveComponent("FolderCreate")!
  const _component_FolderUpdate = _resolveComponent("FolderUpdate")!
  const _component_FolderDelete = _resolveComponent("FolderDelete")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", {
        class: _normalizeClass(["app__layout", { with__modal: _ctx.hasModal }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ], 2),
      _createVNode(_component_ProjectDuplicate),
      _createVNode(_component_ProjectUpdate),
      _createVNode(_component_ProjectDelete),
      _createVNode(_component_FolderDuplicate),
      _createVNode(_component_FolderCreate),
      _createVNode(_component_FolderUpdate),
      _createVNode(_component_FolderDelete)
    ]),
    _: 3
  }))
}