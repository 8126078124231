import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__description" }
const _hoisted_2 = { class: "modal__content" }
const _hoisted_3 = { class: "modal__footer" }
const _hoisted_4 = {
  key: 1,
  class: "email__project__preview__success"
}
const _hoisted_5 = { class: "email__preview__success__message" }
const _hoisted_6 = { class: "email__preview__success__message__close" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ModalLayout = _resolveComponent("ModalLayout")!

  return (_openBlock(), _createBlock(_component_ModalLayout, {
    class: "email__project__preview",
    onClose: _ctx.close,
    open: _ctx.isOpen
  }, {
    heading: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "modal__title" }, "Email Preview", -1)),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.previewMessage), 1)
    ]),
    body: _withCtx(() => [
      (!_ctx.submitted)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_FormGroup, null, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseLabel, { title: "Registered Email Address" }),
                  _createVNode(_component_BaseInput, {
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                    type: "email",
                    placeholder: "Email address",
                    required: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BaseButton, {
                class: "regular",
                title: "Cancel",
                onClick: _ctx.close
              }, null, 8, ["onClick"]),
              _createVNode(_component_BaseButton, {
                disabled: _ctx.disabled,
                type: "submit",
                class: "success",
                title: "Send"
              }, null, 8, ["disabled"])
            ])
          ], 32))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BaseIcon, { icon: "modals/preview/success" }),
              _createElementVNode("p", null, [
                _cache[3] || (_cache[3] = _createTextVNode(" Email sent to ")),
                _createElementVNode("span", null, _toDisplayString(_ctx.email), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_BaseButton, {
                class: "regular",
                title: "Close",
                onClick: _ctx.close
              }, null, 8, ["onClick"])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["onClose", "open"]))
}