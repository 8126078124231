import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__content" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ModalLayout = _resolveComponent("ModalLayout")!

  return (_openBlock(), _createBlock(_component_ModalLayout, {
    class: "share__project__preview",
    onClose: _ctx.close,
    open: _ctx.isOpen
  }, {
    heading: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("h3", { class: "modal__title" }, "Duplicate project", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.duplicateProject && _ctx.duplicateProject(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseLabel, { title: "Project name" }),
            _createVNode(_component_BaseInput, {
              modelValue: _ctx.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              required: ""
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseButton, {
            onClick: _ctx.close,
            title: "Cancel"
          }, null, 8, ["onClick"]),
          _createVNode(_component_BaseButton, {
            disabled: _ctx.disabled,
            class: "success",
            title: "Duplicate Project",
            type: "submit"
          }, null, 8, ["disabled"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["onClose", "open"]))
}