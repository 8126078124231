import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font__size__style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, { title: "Letter spacing" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          class: "canvas__input__number",
          type: "number"
        }, null, 512), [
          [_vModelText, _ctx.model]
        ])
      ])
    ]),
    _: 1
  }))
}