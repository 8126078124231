import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CanvasWorkspaceEmpty = _resolveComponent("CanvasWorkspaceEmpty")!
  const _component_WorkspaceComponentItemsListItem = _resolveComponent("WorkspaceComponentItemsListItem")!
  const _component_WorkspaceLastComponentDecoy = _resolveComponent("WorkspaceLastComponentDecoy")!

  return (_openBlock(), _createElementBlock("div", {
    class: "canvas__workspace__container",
    id: "canvas-workspace-container",
    onMouseover: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)), ["self"]))
  }, [
    _createElementVNode("div", {
      id: "canvas-workspace-items-container",
      class: _normalizeClass(["workspace__component__items__container", _ctx.style.layout])
    }, [
      (_ctx.workspaceComponents.length === 0)
        ? (_openBlock(), _createBlock(_component_CanvasWorkspaceEmpty, {
            key: 0,
            "project-id": _ctx.projectId
          }, null, 8, ["project-id"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workspaceComponents, (componentItem, itemIndex) => {
        return (_openBlock(), _createBlock(_component_WorkspaceComponentItemsListItem, {
          style: {"font-family":"'Agdasima', sans-serif"},
          key: componentItem.id,
          onClicked: _ctx.handleClick,
          onHover: _ctx.handleMouseOver,
          "component-item": componentItem,
          "item-index": itemIndex,
          "project-id": _ctx.projectId,
          "is-mounted": _ctx.isMounted
        }, null, 8, ["onClicked", "onHover", "component-item", "item-index", "project-id", "is-mounted"]))
      }), 128)),
      _withDirectives(_createVNode(_component_WorkspaceLastComponentDecoy, { "project-id": _ctx.projectId }, null, 8, ["project-id"]), [
        [_vShow, _ctx.workspaceComponents.length > 0]
      ])
    ], 2)
  ], 32))
}