import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "canvas__sidebar__nav__content"
}
const _hoisted_2 = { class: "sidebar__nav__contents__header" }
const _hoisted_3 = { class: "sidebar__nav__contents__header__icons" }
const _hoisted_4 = {
  key: 0,
  class: "sidebar__nav__content__item"
}
const _hoisted_5 = {
  key: 1,
  class: "sidebar__nav__content__item"
}
const _hoisted_6 = {
  key: 2,
  class: "sidebar__nav__content__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButtonIcon = _resolveComponent("BaseButtonIcon")!
  const _component_ComponentsContainer = _resolveComponent("ComponentsContainer")!
  const _component_ComponentItemsContainer = _resolveComponent("ComponentItemsContainer")!
  const _component_StylesContainer = _resolveComponent("StylesContainer")!
  const _component_LayersContainer = _resolveComponent("LayersContainer")!

  return (_ctx.sidebarNavContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.formatContentTitle), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseButtonIcon, {
              onClick: _ctx.toggleSidebarDock,
              class: _normalizeClass(["content__sidebar__dock__button", { active: _ctx.sidebarDock }]),
              icon: "canvas/sidebar/content/dock"
            }, null, 8, ["onClick", "class"]),
            _createVNode(_component_BaseButtonIcon, {
              onClick: _ctx.closeSidebarNavContent,
              icon: "canvas/sidebar/content/close"
            }, null, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["sidebar__nav__contents__body", {
        has__modal: _ctx.GENERAL_STYLE_TYPE_COLORS.includes(_ctx.colorPicker),
      }])
        }, [
          (_ctx.showContent('add_component'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ComponentsContainer),
                _createVNode(_component_ComponentItemsContainer)
              ]))
            : _createCommentVNode("", true),
          (_ctx.showContent('style'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_StylesContainer)
              ]))
            : _createCommentVNode("", true),
          (_ctx.showContent('layers'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_LayersContainer)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}