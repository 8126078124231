import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "layers__container" }
const _hoisted_2 = { class: "layers__component__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayersContainerItem = _resolveComponent("LayersContainerItem")!
  const _component_LayerLastContainerElementDecoy = _resolveComponent("LayerLastContainerElementDecoy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workspaceComponents, (component, itemIndex) => {
        return (_openBlock(), _createBlock(_component_LayersContainerItem, {
          key: component.id,
          componentItem: component,
          itemIndex: itemIndex,
          "show-elements": _ctx.tabStates[itemIndex]
        }, null, 8, ["componentItem", "itemIndex", "show-elements"]))
      }), 128)),
      (_ctx.workspaceComponents.length > 0)
        ? (_openBlock(), _createBlock(_component_LayerLastContainerElementDecoy, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}