import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "canvas__sidebar__nav" }
const _hoisted_2 = { class: "sidebar__nav__top" }
const _hoisted_3 = { class: "sidebar__nav__bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButtonIcon = _resolveComponent("BaseButtonIcon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarNavTopIcons, (button, key) => {
        return (_openBlock(), _createBlock(_component_BaseButtonIcon, {
          key: key,
          onClick: ($event: any) => (_ctx.changeSidebarNavContent(button.name)),
          icon: button.icon,
          class: _normalizeClass({ active: _ctx.activeContent(button.name) })
        }, null, 8, ["onClick", "icon", "class"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarNavBottomIcons, (button, key) => {
        return (_openBlock(), _createBlock(_component_BaseButtonIcon, {
          key: key,
          icon: button.icon
        }, null, 8, ["icon"]))
      }), 128)),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleUserInitials && _ctx.toggleUserInitials(...args))),
        id: "modals-trigger",
        class: "sidebar__nav__bottom__initials"
      }, _toDisplayString(_ctx.getInitials), 1)
    ])
  ]))
}