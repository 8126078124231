import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__title" }
const _hoisted_2 = { class: "modal__content" }
const _hoisted_3 = { class: "modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ModalLayout = _resolveComponent("ModalLayout")!

  return (_openBlock(), _createBlock(_component_ModalLayout, {
    class: "confirm__modal",
    onClose: _ctx.cancel,
    open: true
  }, {
    heading: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseButton, {
          onClick: _ctx.cancel,
          title: "No, cancel"
        }, null, 8, ["onClick"]),
        _createVNode(_component_BaseButton, {
          onClick: _withModifiers(_ctx.confirm, ["stop"]),
          disabled: _ctx.disabled,
          class: "danger",
          title: "Yes, delete",
          type: "submit"
        }, null, 8, ["onClick", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}