import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "screen-size-constraint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_ctx.isSmallScreen && _ctx.isCanvasRoute)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.constraintStyle),
          class: "screen-size-constraint__modal"
        }, [
          _createVNode(_component_BaseIcon, { icon: "canvas/size-constraint" }),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "screen-size-constraint__content" }, [
            _createElementVNode("h5", null, "Your browser size is to small"),
            _createElementVNode("p", null, " Resize your browser to be at least 900px wide to continue designing ")
          ], -1))
        ], 4)
      ]))
    : _createCommentVNode("", true)
}