import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownLayout = _resolveComponent("DropdownLayout")!

  return (_openBlock(), _createBlock(_component_DropdownLayout, {
    class: _normalizeClass({ open: _ctx.open }),
    data: _ctx.data,
    onClicks: _ctx.handleEvents
  }, null, 8, ["class", "data", "onClicks"]))
}