import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button__text__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_BaseIcon, { icon: _ctx.icon }, null, 8, ["icon"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
  ]))
}