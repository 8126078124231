<template>
  <DropdownLayout :class="{ open }" :data="data" @clicks="handleEvents" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DropdownLayout from "@/components/layout/dropdown/DropdownLayout.vue";
import store from "@/store";
export default defineComponent({
  name: "ProjectItemDropdown",
  components: { DropdownLayout },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    const data = [
      {
        icon: "projects/dropdown/duplicate",
        name: "Duplicate",
        event: "duplicate",
      },
      {
        icon: "projects/dropdown/edit",
        name: "Rename project",
        event: "rename",
      },
      {
        icon: "projects/dropdown/delete",
        name: "Delete",
        event: "delete",
      },
    ];

    const handleEvents = (event: string) => {
      switch (event) {
        case data[0].event: {
          store.commit("modals/OPEN_MODAL", "project_duplicate");
          store.commit("modals/CLOSE_MODAL", "project_item");
          break;
        }
        case data[1].event: {
          store.commit("modals/OPEN_MODAL", "project_update");
          store.commit("modals/CLOSE_MODAL", "project_item");
          break;
        }
        case data[2].event: {
          store.commit("modals/OPEN_MODAL", "project_delete");
          store.commit("modals/CLOSE_MODAL", "project_item");
          break;
        }
      }
    };

    return {
      data,
      handleEvents,
    };
  },
});
</script>

<style></style>
