import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "workspace__component__item__actions" }
const _hoisted_2 = { class: "workspace__component__actions__top" }
const _hoisted_3 = { class: "workspace__component__actions__bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButtonIcon = _resolveComponent("BaseButtonIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseButtonIcon, {
        disabled: _ctx.disabledButton,
        onClick: _ctx.duplicateProjectComponent,
        icon: "canvas/workspace/duplicate"
      }, null, 8, ["disabled", "onClick"]),
      _createVNode(_component_BaseButtonIcon, {
        disabled: _ctx.disabledButton,
        onClick: _ctx.deleteProjectComponent,
        icon: "canvas/workspace/delete"
      }, null, 8, ["disabled", "onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BaseButtonIcon, {
        disabled: _ctx.disabledTopModifyPosition,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modifyComponentPosition(false))),
        icon: "arrow/up"
      }, null, 8, ["disabled"]),
      _createVNode(_component_BaseButtonIcon, {
        disabled: _ctx.disabledBottomModifyPosition,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modifyComponentPosition())),
        icon: "arrow/down"
      }, null, 8, ["disabled"])
    ])
  ]))
}