import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__content" }
const _hoisted_2 = { class: "share__project__preview__link" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ModalLayout = _resolveComponent("ModalLayout")!

  return (_openBlock(), _createBlock(_component_ModalLayout, {
    class: "share__project__preview",
    onClose: _ctx.close,
    open: _ctx.isOpen
  }, {
    heading: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("h3", { class: "modal__title" }, "Share a view-only link", -1),
      _createElementVNode("p", { class: "modal__description" }, " Anybody wth this link will be able to view your email design - no login required ", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseIcon, { icon: "link" }),
            _createElementVNode("a", {
              href: _ctx.previewURL,
              target: "_blank"
            }, _toDisplayString(_ctx.sliceString(_ctx.previewURL, 59)), 9, _hoisted_3)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BaseButton, {
            onClick: _ctx.copyURL,
            class: "success",
            title: _ctx.copied ? 'Copied!' : 'Copy link'
          }, null, 8, ["onClick", "title"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "open"]))
}