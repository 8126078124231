import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "workspace__component__items__list" }
const _hoisted_2 = ["innerHTML", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkspaceComponentDropIndicator = _resolveComponent("WorkspaceComponentDropIndicator")!
  const _component_WorkspaceComponentDropSkeleton = _resolveComponent("WorkspaceComponentDropSkeleton")!
  const _component_WorkspaceComponentItemsActions = _resolveComponent("WorkspaceComponentItemsActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dropIndex === _ctx.itemIndex)
      ? (_openBlock(), _createBlock(_component_WorkspaceComponentDropIndicator, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.dropLoadingIndex === _ctx.itemIndex)
      ? (_openBlock(), _createBlock(_component_WorkspaceComponentDropSkeleton, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isMounted)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["workspace__component__items__list__item", {
        focused: _ctx.focusedIndex === _ctx.itemIndex,
        hovered: _ctx.currentHoverElement.componentIndex === _ctx.itemIndex,
      }]),
          innerHTML: _ctx.componentItem.html,
          id: `workspace-component-item-${_ctx.itemIndex}`,
          draggable: true,
          onDragstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.moveComponentItemPosition($event, _ctx.itemIndex))),
          onDrop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dropComponent($event, _ctx.itemIndex, _ctx.projectId))),
          onDblclick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dbClickEvent && _ctx.dbClickEvent(...args))),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickEvent($event))),
          onKeyup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleKeyUp($event))),
          onMouseover: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.hoverEvent($event)), ["stop"])),
          onDragover: [
            _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleDragOver($event))),
            _cache[9] || (_cache[9] = _withModifiers(() => {}, ["prevent"]))
          ],
          onDragenter: [
            _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleDragEnter && _ctx.handleDragEnter(...args))),
            _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"]))
          ],
          onDragleave: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args)))
        }, null, 42, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.showActions)
      ? (_openBlock(), _createBlock(_component_WorkspaceComponentItemsActions, {
          key: 3,
          "component-item": _ctx.componentItem,
          "project-id": _ctx.projectId,
          "item-index": _ctx.itemIndex
        }, null, 8, ["component-item", "project-id", "item-index"]))
      : _createCommentVNode("", true)
  ]))
}