import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "projects__section" }
const _hoisted_2 = {
  key: 1,
  class: "projects__body"
}
const _hoisted_3 = { class: "projects__body__list__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectsHeader = _resolveComponent("ProjectsHeader")!
  const _component_FilteredProjectsEmpty = _resolveComponent("FilteredProjectsEmpty")!
  const _component_ProjectListItem = _resolveComponent("ProjectListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProjectsHeader, { onUpdateSearchName: _ctx.updateSearchName }, null, 8, ["onUpdateSearchName"]),
    (_ctx.filteredProjects.length === 0)
      ? (_openBlock(), _createBlock(_component_FilteredProjectsEmpty, {
          key: 0,
          "search-name": _ctx.searchName
        }, null, 8, ["search-name"]))
      : _createCommentVNode("", true),
    (_ctx.filteredProjects.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "projects__body__title" }, "Projects", -1)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (project, key) => {
              return (_openBlock(), _createBlock(_component_ProjectListItem, {
                project: project,
                index: key + 1,
                key: project.id
              }, null, 8, ["project", "index"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}