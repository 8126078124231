import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth__verify" }
const _hoisted_2 = { class: "auth__verify__content" }
const _hoisted_3 = { class: "auth__form-content-desc" }
const _hoisted_4 = { class: "form__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseIcon, {
      class: "auth__verify__icon",
      icon: "auth/verify"
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "auth__form-content-title" }, "Verify account", -1)),
      _createElementVNode("p", _hoisted_3, [
        _cache[1] || (_cache[1] = _createTextVNode(" A verification link has been sent to ")),
        _createElementVNode("span", null, _toDisplayString(_ctx.email), 1),
        _cache[2] || (_cache[2] = _createTextVNode(". Follow the instructions to activate your account. "))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, [
        _cache[4] || (_cache[4] = _createTextVNode(" Didn’t get an email? ")),
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resendVerification && _ctx.resendVerification(...args)))
        }, "Resend")
      ])
    ])
  ]))
}