import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "panel__style__tabs" }
const _hoisted_2 = { class: "panel__style__tabs__head" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "panel__style__tabs__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titles, (title, key) => {
        return (_openBlock(), _createElementBlock("button", {
          key: key,
          onClick: ($event: any) => (_ctx.toggle(key)),
          class: _normalizeClass({ active: _ctx.activeIndex === key })
        }, _toDisplayString(title), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}