import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal__header" }
const _hoisted_2 = { class: "modal__heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    id: "modals",
    class: _normalizeClass(["modal", { open: _ctx.open }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        id: "modals-trigger",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
        class: "modal__header__close"
      }, [
        _createVNode(_component_BaseIcon, { icon: "close" })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "heading")
      ])
    ]),
    _renderSlot(_ctx.$slots, "body")
  ], 2))
}