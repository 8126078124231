import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "action__dropdown__item" }
const _hoisted_2 = { class: "action__dropdown__item__icon" }
const _hoisted_3 = { class: "action__dropdown__item__titles" }
const _hoisted_4 = { class: "dropdown__item__titles__name" }
const _hoisted_5 = {
  key: 0,
  class: "dropdown__item__titles__desc"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseIcon, { icon: _ctx.icon }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.disabled && (_ctx.current === _ctx.type || !_ctx.type))
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 0,
          class: "action__dropdown__item__loader",
          icon: "loader"
        }))
      : _createCommentVNode("", true)
  ]))
}