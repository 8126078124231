import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkspaceComponentDropIndicator = _resolveComponent("WorkspaceComponentDropIndicator")!
  const _component_WorkspaceComponentDropSkeleton = _resolveComponent("WorkspaceComponentDropSkeleton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "workspace__component__items__list",
    onDrop: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dropComponent($event, _ctx.itemIndex, _ctx.projectId))),
    onDragover: [
      _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
      _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args)))
    ],
    onDragenter: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
    onDragleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args)))
  }, [
    (_ctx.drop)
      ? (_openBlock(), _createBlock(_component_WorkspaceComponentDropIndicator, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.dropLoading || _ctx.dropLoadingState)
      ? (_openBlock(), _createBlock(_component_WorkspaceComponentDropSkeleton, { key: 1 }))
      : _createCommentVNode("", true),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "workspace__component__items__list__item decoy" }, null, -1))
  ], 32))
}