import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createBlock(_component_FormGroup, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseLabel, { title: _ctx.title }, null, 8, ["title"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BaseInput, {
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          type: `${_ctx.hidePassword ? 'password' : 'text'}`,
          placeholder: "Password",
          required: ""
        }, null, 8, ["value", "type"]),
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePassword && _ctx.togglePassword(...args))),
          class: "input__group__append"
        }, [
          _createVNode(_component_BaseIcon, {
            icon: `auth/password/${_ctx.hidePassword ? 'hide' : 'show'}`
          }, null, 8, ["icon"])
        ])
      ])
    ]),
    _: 1
  }))
}