import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "projects__body__list__item" }
const _hoisted_2 = { class: "projects__body__item__body__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectListItemPlaceholder = _resolveComponent("ProjectListItemPlaceholder")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ProjectItemDropdown = _resolveComponent("ProjectItemDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProjectListItemPlaceholder, {
      onClick: _ctx.openProject,
      project: _ctx.project
    }, null, 8, ["onClick", "project"]),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openProject && _ctx.openProject(...args))),
      class: "projects__body__item__body"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.sliceString(_ctx.project.name, 16)), 1),
        _createElementVNode("h4", null, "Edited " + _toDisplayString(_ctx.diffForHumans(_ctx.project.updatedAt)), 1)
      ]),
      _createElementVNode("span", {
        id: "modals-trigger",
        class: "projects__body__item__body__open",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)), ["stop"]))
      }, [
        _createVNode(_component_BaseIcon, { icon: "hamburger/horizontal" })
      ])
    ]),
    _createVNode(_component_ProjectItemDropdown, { open: _ctx.open }, null, 8, ["open"])
  ]))
}