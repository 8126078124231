import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["toast__message", _ctx.type])
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_BaseIcon, {
            icon: `toast/${_ctx.type}`
          }, null, 8, ["icon"]),
          _createElementVNode("h6", null, _toDisplayString(_ctx.message), 1),
          (_ctx.data.actionName && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toastAction && _ctx.toastAction(...args)))
              }, _toDisplayString(_ctx.data.actionName), 1))
            : _createCommentVNode("", true),
          (_ctx.data.actionName && _ctx.loading)
            ? (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 1,
                class: "loader",
                icon: "loader"
              }))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}