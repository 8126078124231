import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "auth-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default"),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "auth__terms__privacy" }, [
          _createElementVNode("a", {
            target: "_blank",
            href: "https://drawberry.io/privacy-and-terms"
          }, "Terms of use"),
          _createElementVNode("span", null, "|"),
          _createElementVNode("a", {
            target: "_blank",
            href: "https://drawberry.io/privacy-and-terms#privacy"
          }, "Privacy policy")
        ], -1))
      ])
    ]),
    _: 3
  }))
}