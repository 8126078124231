import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "manage__esps__list__item" }
const _hoisted_2 = { class: "manage__esps__list__item__content" }
const _hoisted_3 = { class: "esps__list__item__icon" }
const _hoisted_4 = { class: "esps__list__item__titles" }
const _hoisted_5 = { class: "dropdown__item__titles__name" }
const _hoisted_6 = { class: "dropdown__item__titles__desc" }
const _hoisted_7 = { class: "manage__esps__list__item__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseIcon, {
          icon: `header/export/${_ctx.esp}`
        }, null, 8, ["icon"]),
        _createElementVNode("span", {
          class: _normalizeClass([{ active: _ctx.checkESPForUser }, "esp__icon__status"])
        }, null, 2)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.getESPSubTitle()), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_BaseButton, {
        onClick: _ctx.handleAction,
        class: "button__outline",
        disabled: _ctx.disabled,
        title: !_ctx.disabled ? _ctx.buttonText : 'Disabled'
      }, null, 8, ["onClick", "disabled", "title"])
    ])
  ]))
}