import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "align__style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButtonIcon = _resolveComponent("BaseButtonIcon")!
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, { title: "Vertical Align" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignOptions, (option, key) => {
          return (_openBlock(), _createBlock(_component_BaseButtonIcon, {
            key: key,
            class: _normalizeClass({ active: option.align === _ctx.align }),
            icon: option.icon,
            onClick: ($event: any) => (_ctx.changeAlignment(option.align))
          }, null, 8, ["class", "icon", "onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}