import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "modals",
  class: "action__dropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionDropdownItem = _resolveComponent("ActionDropdownItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (dropdown, key) => {
      return (_openBlock(), _createBlock(_component_ActionDropdownItem, {
        onClick: ($event: any) => (_ctx.callEvent(dropdown)),
        key: key,
        title: dropdown.name,
        icon: dropdown.icon
      }, null, 8, ["onClick", "title", "icon"]))
    }), 128))
  ]))
}