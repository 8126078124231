import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownLayout = _resolveComponent("DropdownLayout")!

  return (_openBlock(), _createBlock(_component_DropdownLayout, {
    class: "user-initials",
    onClicks: _ctx.handleClick,
    data: _ctx.data
  }, null, 8, ["onClicks", "data"]))
}