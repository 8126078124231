import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (!_ctx.header)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{ hover: _ctx.isHover, focus: _ctx.isFocus }, "layers__component__item__element"])
      }, [
        _createVNode(_component_BaseIcon, { icon: _ctx.icon }, null, 8, ["icon"]),
        _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1)
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass([{ hover: _ctx.isHover, focus: _ctx.isFocus }, "layers__component__item__header"])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2))
}