import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout__style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, { title: "Layout" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLayout('collapsed'))),
          class: _normalizeClass([{ active: _ctx.isActive('collapsed') }, "layout__style__item layout__style__collapsed"])
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("div", null, null, -1)
        ]), 2),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLayout('cards'))),
          class: _normalizeClass([{ active: _ctx.isActive('cards') }, "layout__style__item layout__style__cards"])
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("div", null, null, -1),
          _createElementVNode("div", null, null, -1),
          _createElementVNode("div", null, null, -1)
        ]), 2)
      ])
    ]),
    _: 1
  }))
}