import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "canvas__layout" }
const _hoisted_2 = { class: "canvas__sidebar" }
const _hoisted_3 = { class: "canvas__sidebar__container" }
const _hoisted_4 = { class: "canvas__panel__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["canvas__container", [
          {
            nav__content__docked: _ctx.docked,
            has__right__panel: _ctx.hasWorkspaceComponent,
          },
        ]])
        }, [
          _createElementVNode("aside", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "sidebar")
            ])
          ]),
          _createElementVNode("section", {
            class: "canvas__workspace",
            style: _normalizeStyle(_ctx.styles),
            id: "canvas-workspace",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickEmpty && _ctx.handleClickEmpty(...args)))
          }, [
            _renderSlot(_ctx.$slots, "workspace")
          ], 4),
          (_ctx.hasWorkspaceComponent)
            ? (_openBlock(), _createElementBlock("section", {
                key: 0,
                class: _normalizeClass(["canvas__panel", {
            has__modal: _ctx.PANEL_STYLE_TYPE_COLORS.includes(_ctx.colorPicker),
          }]),
                id: "canvas-panel"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "panel")
                ])
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        _renderSlot(_ctx.$slots, "modals")
      ])
    ]),
    _: 3
  }))
}