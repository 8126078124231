import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "auth__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_ctx.message)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BaseIcon, { icon: "auth/error" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
      ]))
    : _createCommentVNode("", true)
}