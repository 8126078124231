import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "export__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionDropdownItem = _resolveComponent("ActionDropdownItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ActionDropdownItem, {
      onClick: _ctx.downloadProjectHTML,
      title: _ctx.downloadData.name,
      icon: _ctx.downloadData.icon,
      class: _normalizeClass({ disabled: _ctx.disabled }),
      disabled: _ctx.disabled
    }, null, 8, ["onClick", "title", "icon", "class", "disabled"])
  ]))
}