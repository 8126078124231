import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "modals",
  class: "header-export action__dropdown"
}
const _hoisted_2 = {
  key: 0,
  class: "export__empty"
}
const _hoisted_3 = { class: "export__empty__icons" }
const _hoisted_4 = { class: "export__empty__content" }
const _hoisted_5 = {
  key: 1,
  class: "export__esps"
}
const _hoisted_6 = { class: "export__esps__header" }
const _hoisted_7 = { class: "export__esps__lists" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ExportESPsList = _resolveComponent("ExportESPsList")!
  const _component_ActionDropdownItem = _resolveComponent("ActionDropdownItem")!
  const _component_DownloadProjectHTML = _resolveComponent("DownloadProjectHTML")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isEspEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (esp, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "export__empty__icon",
                key: key
              }, [
                _createVNode(_component_BaseIcon, {
                  icon: esp.icon
                }, null, 8, ["icon"])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[1] || (_cache[1] = _createElementVNode("h6", null, "No connected apps", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", null, "You haven't let any apps access your account yet.", -1)),
            _createVNode(_component_BaseButton, {
              onClick: _ctx.openManage,
              class: "button__outline",
              title: "Manage apps"
            }, null, 8, ["onClick"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("h5", null, "Connected apps", -1)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openManage && _ctx.openManage(...args)))
            }, [
              _createVNode(_component_BaseIcon, { icon: "settings" })
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ExportESPsList),
            _createVNode(_component_ActionDropdownItem, {
              onClick: _ctx.openManage,
              class: "export__esps__lists__add",
              title: "Add new app",
              icon: "add",
              subtitle: "5+ apps to integrate with"
            }, null, 8, ["onClick"])
          ])
        ])),
    _createVNode(_component_DownloadProjectHTML)
  ]))
}