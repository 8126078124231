import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionDropdownItem = _resolveComponent("ActionDropdownItem")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.esps, (esp_item) => {
    return (_openBlock(), _createBlock(_component_ActionDropdownItem, {
      onClick: ($event: any) => (_ctx.exportProject(esp_item.esp)),
      key: esp_item.id,
      type: esp_item.esp,
      title: `Send to ${_ctx.capitalizeFirstLetter(esp_item.esp)}`,
      icon: `header/export/${esp_item.esp}`,
      subtitle: esp_item.accountEmail,
      disabled: _ctx.disabled,
      current: _ctx.current,
      class: _normalizeClass({ disabled: _ctx.disabled })
    }, null, 8, ["onClick", "type", "title", "icon", "subtitle", "disabled", "current", "class"]))
  }), 128))
}