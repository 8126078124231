import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "canvas__panel__empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_BaseIcon, { icon: "canvas/panel/empty" })
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("p", null, " You can select an element on the canvas to reveal the edit options on this panel. ", -1))
  ]))
}