import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CanvasPanelGroupedStyles = _resolveComponent("CanvasPanelGroupedStyles")!
  const _component_CanvasPanelEmpty = _resolveComponent("CanvasPanelEmpty")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_ctx.focusedElement)
      ? (_openBlock(), _createBlock(_component_CanvasPanelGroupedStyles, { key: 0 }))
      : (_ctx.hasWorkspaceComponent)
        ? (_openBlock(), _createBlock(_component_CanvasPanelEmpty, { key: 1 }))
        : _createCommentVNode("", true)
  ]))
}