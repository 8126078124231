<template>
  <div class="layer__element__drop__indicator"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LayerElementDropIndicator",
});
</script>

<style scoped></style>
