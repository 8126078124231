import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "projects__empty filtered"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.searchName)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h6", null, [
          _createElementVNode("span", null, "“" + _toDisplayString(_ctx.searchName) + "”", 1),
          _cache[0] || (_cache[0] = _createTextVNode(" not found within projects "))
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("p", null, "Try another search word", -1))
      ]))
    : _createCommentVNode("", true)
}