import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "canvas__workspace__empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkspaceComponentDropSkeleton = _resolveComponent("WorkspaceComponentDropSkeleton")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "canvas__workspace__empty__container",
    onDrop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dropComponent($event, 0, _ctx.projectId))),
    onDragover: [
      _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args))),
      _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
    ],
    onDragenter: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
  }, [
    (_ctx.dropLoading || _ctx.dropLoadingState)
      ? (_openBlock(), _createBlock(_component_WorkspaceComponentDropSkeleton, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("p", null, [
            _createTextVNode(" Hit the "),
            _createElementVNode("span", null, "+"),
            _createTextVNode(" button to "),
            _createElementVNode("span", null, "drag and drop"),
            _createTextVNode(" components from the component panel ")
          ], -1)),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openSidebar && _ctx.openSidebar(...args)))
          }, [
            _createVNode(_component_BaseIcon, { icon: "add" })
          ])
        ]))
  ], 32))
}