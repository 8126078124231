import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ManageESPs = _resolveComponent("ManageESPs")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleGlobalClick && _ctx.handleGlobalClick(...args)))
  }, [
    _createVNode(_component_Header),
    _createElementVNode("main", {
      class: _normalizeClass(["main", { 'small-screen': _ctx.isSmallScreen }])
    }, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.showESPMange)
        ? (_openBlock(), _createBlock(_component_ManageESPs, { key: 0 }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}