import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layers__component__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayerElementDropIndicator = _resolveComponent("LayerElementDropIndicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.drop)
      ? (_openBlock(), _createBlock(_component_LayerElementDropIndicator, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "layers__component__item__header decoy",
      onDrop: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dropComponentItemLayer($event))),
      onDragover: [
        _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args))),
        _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      ],
      onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args))),
      onDragenter: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
    }, null, 32)
  ]))
}