import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColorPickerStyle = _resolveComponent("ColorPickerStyle")!
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, {
    name: "color",
    title: "Text COLOR"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ColorPickerStyle, {
        type: _ctx.ColorPickerTypes.PANEL_STYLE_TEXT_COLOR,
        ref: "colorPickerStyleRef",
        color: _ctx.color,
        onUpdateColor: _ctx.updateColor
      }, null, 8, ["type", "color", "onUpdateColor"])
    ]),
    _: 1
  }))
}