import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "manage__esps" }
const _hoisted_2 = { class: "manage__esps__header" }
const _hoisted_3 = { class: "manage__esps__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButtonIcon = _resolveComponent("BaseButtonIcon")!
  const _component_ManageESPListItem = _resolveComponent("ManageESPListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Manage apps", -1)),
      _createVNode(_component_BaseButtonIcon, {
        onClick: _ctx.close,
        class: "manage__esps__header__close",
        icon: "close"
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (dropdown, key) => {
        return (_openBlock(), _createBlock(_component_ManageESPListItem, {
          key: key,
          title: dropdown.title,
          esp: dropdown.esp
        }, null, 8, ["title", "esp"]))
      }), 128))
    ])
  ]))
}