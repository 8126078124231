import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "projects__body__item__header",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", {
      class: "project__item__components__placeholder",
      innerHTML: _ctx.html
    }, null, 8, _hoisted_1)
  ], 4))
}