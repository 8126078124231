import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "layers__component__item__header__move" }
const _hoisted_3 = {
  key: 1,
  class: "layers__component__item__elements"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayerElementDropIndicator = _resolveComponent("LayerElementDropIndicator")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_LayersContainerElementItem = _resolveComponent("LayersContainerElementItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layers__component__item", {
      focused: _ctx.focusedIndex === _ctx.itemIndex,
      hovered: _ctx.currentHoverElement.componentIndex === _ctx.itemIndex,
    }]),
    id: `layer-component-item-${_ctx.itemIndex}`
  }, [
    (_ctx.dropIndex === _ctx.itemIndex)
      ? (_openBlock(), _createBlock(_component_LayerElementDropIndicator, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_LayersContainerElementItem, {
      onMouseover: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleMouseOver(_ctx.componentItem.json[0])), ["stop"])),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClick(_ctx.componentItem.json[0]))),
      id: _ctx.componentItem.json[0].id,
      onDblclick: _ctx.closeAllTabs,
      element: _ctx.componentItem.json[0],
      componentItem: _ctx.componentItem,
      "item-index": _ctx.itemIndex,
      header: true,
      draggable: true,
      onDragstart: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.dragComponentItem($event)), ["self"])),
      onDrop: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dropComponentItemLayer($event))),
      onDragover: [
        _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleDragOver($event))),
        _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
      ],
      onDragleave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleDragLeave($event))),
      onDragenter: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", null, [
            _createVNode(_component_BaseIcon, { icon: "canvas/sidebar/layers/dots" })
          ])
        ]),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShowElements && _ctx.toggleShowElements(...args)), ["stop"])),
          class: "layers__component__item__header__switch"
        }, [
          _createVNode(_component_BaseIcon, {
            icon: `canvas/sidebar/layers/${_ctx.showElements ? 'open' : 'closed'}`
          }, null, 8, ["icon"])
        ]),
        _createVNode(_component_BaseIcon, { icon: "canvas/sidebar/layers/component" }),
        _cache[9] || (_cache[9] = _createElementVNode("h5", { class: "layers__component__item__title" }, "Component", -1))
      ]),
      _: 1
    }, 8, ["id", "onDblclick", "element", "componentItem", "item-index"]),
    (_ctx.showElements)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentItem.json.slice(1), (element) => {
            return _withDirectives((_openBlock(), _createBlock(_component_LayersContainerElementItem, {
              id: element.id,
              key: element.id,
              onMouseover: _withModifiers(($event: any) => (_ctx.handleMouseOver(element)), ["stop"]),
              onClick: ($event: any) => (_ctx.handleClick(element)),
              element: element,
              componentItem: _ctx.componentItem
            }, null, 8, ["id", "onMouseover", "onClick", "element", "componentItem"])), [
              [_vShow, !_ctx.isChild(element)]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}