import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header__container__right" }
const _hoisted_2 = {
  key: 0,
  class: "header__right__canvas__actions"
}
const _hoisted_3 = { class: "header__redo" }
const _hoisted_4 = {
  key: 1,
  class: "header__right__preview"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButtonTextIcon = _resolveComponent("BaseButtonTextIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ExportDropdown = _resolveComponent("ExportDropdown")!
  const _component_PreviewDropdown = _resolveComponent("PreviewDropdown")!
  const _component_BaseButtonIcon = _resolveComponent("BaseButtonIcon")!
  const _component_UserInitialsDropdown = _resolveComponent("UserInitialsDropdown")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAuth)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isCanvas && _ctx.project)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("button", null, [
                    _createVNode(_component_BaseIcon, { icon: "header/redo/backward" })
                  ]),
                  _createElementVNode("button", null, [
                    _createVNode(_component_BaseIcon, { icon: "header/redo/forward" })
                  ])
                ]),
                _createVNode(_component_BaseButtonTextIcon, {
                  id: _ctx.modalsTrigger,
                  onClick: _ctx.togglePreview,
                  text: "Preview",
                  icon: "header/preview"
                }, null, 8, ["id", "onClick"]),
                _createVNode(_component_BaseButton, {
                  id: _ctx.modalsTrigger,
                  class: "header__right__share button__outline",
                  title: "Share",
                  onClick: _ctx.toggleShare
                }, null, 8, ["id", "onClick"]),
                _createVNode(_component_BaseButtonTextIcon, {
                  id: _ctx.modalsTrigger,
                  onClick: _ctx.toggleExport,
                  class: "success",
                  text: "Export",
                  icon: "header/export"
                }, null, 8, ["id", "onClick"]),
                _createVNode(_component_ExportDropdown, {
                  class: _normalizeClass({ open: _ctx.openExport })
                }, null, 8, ["class"]),
                _createVNode(_component_PreviewDropdown, {
                  "is-preview": _ctx.isPreview,
                  class: _normalizeClass({ open: _ctx.openPreview })
                }, null, 8, ["is-preview", "class"])
              ]))
            : (_ctx.project && _ctx.isPreview && _ctx.isUserProject)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_BaseButtonIcon, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToProject())),
                    icon: "close"
                  })
                ]))
              : ((_ctx.isPreview && !_ctx.isUserProject) || _ctx.isADiffRoute)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", {
                      class: "header__right__initials",
                      id: _ctx.modalsTrigger,
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleUserInitials && _ctx.toggleUserInitials(...args)))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getInitials), 1),
                      _createVNode(_component_UserInitialsDropdown, {
                        class: _normalizeClass({ open: _ctx.openUserInitials })
                      }, null, 8, ["class"])
                    ], 8, _hoisted_6)
                  ]))
                : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isPreview)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/login",
                class: "button grey"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Login ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}