import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "projects__empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButtonTextIcon = _resolveComponent("BaseButtonTextIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h6", null, "Start designing your audience’s inbox", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", null, "Bring your ideas to life!", -1)),
    _createVNode(_component_BaseButtonTextIcon, {
      onClick: _ctx.createProject,
      class: "grey projects__empty__btn",
      icon: "add",
      text: "New Project"
    }, null, 8, ["onClick"])
  ]))
}