import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider__icon__container" }
const _hoisted_2 = ["min", "max", "value"]
const _hoisted_3 = { class: "slider__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseIcon, { icon: _ctx.icon }, null, 8, ["icon"]),
    _createElementVNode("input", {
      type: "range",
      min: _ctx.min,
      max: _ctx.max,
      class: "slider__input",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      value: _ctx.modelValue
    }, null, 40, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.modelValue), 1),
      _cache[1] || (_cache[1] = _createElementVNode("h6", null, "PX", -1))
    ])
  ]))
}