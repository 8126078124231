import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar__styles__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarLayoutStyle = _resolveComponent("SidebarLayoutStyle")!
  const _component_SidebarBackgroundColorStyle = _resolveComponent("SidebarBackgroundColorStyle")!
  const _component_SidebarFontStyle = _resolveComponent("SidebarFontStyle")!
  const _component_SidebarBackgroundImageStyle = _resolveComponent("SidebarBackgroundImageStyle")!
  const _component_SidebarPreviewTextStyle = _resolveComponent("SidebarPreviewTextStyle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SidebarLayoutStyle),
    _createVNode(_component_SidebarBackgroundColorStyle),
    _createVNode(_component_SidebarFontStyle),
    _createVNode(_component_SidebarBackgroundImageStyle),
    _createVNode(_component_SidebarPreviewTextStyle)
  ]))
}